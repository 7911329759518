import { createSlice } from '@reduxjs/toolkit'

// create slice

const name = 'global'
const initialState = createInitialState()
const reducers = createReducers()
const slice = createSlice({ name, initialState, reducers })

// exports

// export const globalActions = { ...slice.actions, ...extraActions }
// const acciones = slice.actions
// export const globalActions = { ...acciones }
export const globalActions = slice.actions
export const globalReducer = slice.reducer

// implementation

function createInitialState() {
  return {
    barraLateralVisible: true,
    barraLateralDesplegable: true,
  }
}

function createReducers() {
  return {
    mostrarBarraLateral,
    desplegarBarraLateralDesplegable,
  }

  function mostrarBarraLateral(state, action) {
    state.barraLateralVisible = action.payload
  }

  function desplegarBarraLateralDesplegable(state, action) {
    state.barraLateralDesplegable = action.payload
  }
}

import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilFile } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import bajoLogo from './../../assets/images/logo.jpg'
import { useDispatch } from 'react-redux'
import { authActions } from '../../store'

const AppHeaderDropdown = () => {
  const dispatch = useDispatch()

  const handleClick = (e) => {
    e.preventDefault()
    dispatch(authActions.logout())
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={bajoLogo} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Sesión</CDropdownHeader>
        <CDropdownItem href="#" onClick={handleClick}>
          <CIcon icon={cilFile} className="me-2" />
          Salir
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown

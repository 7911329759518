import { configureStore } from '@reduxjs/toolkit'

import { alertReducer } from './alert.slice'
import { authReducer } from './auth.slice'
import { usersReducer } from './users.slice'
import { globalReducer } from './global.slice'

export * from './alert.slice'
export * from './auth.slice'
export * from './users.slice'
export * from './global.slice'

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    users: usersReducer,
    global: globalReducer,
  },
})

import React from 'react'
import { useLocation, Link } from 'react-router-dom'

import routes from '../routes'

// import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    let rutaConParametro = false
    const currentRoute = routes.find((route) => {
      let indice = route.path.lastIndexOf('/')
      let porcionDeRuta = route.path.substring(indice + 1, indice + 2)

      if (porcionDeRuta == ':') {
        rutaConParametro = true
        return route.path.substring(0, indice) === pathname
        // if (route.path.substring(0, indice) === pathname) return pathname
      } else {
        if (route.path === pathname) return pathname
      }
    })

    return currentRoute ? [currentRoute.name, rutaConParametro] : [false, rutaConParametro]
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []

    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const [routeName, rutaConParametro] = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length || rutaConParametro ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    // <CBreadcrumb className="m-0 ms-2">
    //   <CBreadcrumbItem href={homepage}>Inicio</CBreadcrumbItem>
    //   {breadcrumbs.map((breadcrumb, index) => {
    //     return (
    //       <CBreadcrumbItem
    //         {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
    //         key={index}
    //       >
    //         {breadcrumb.name}
    //       </CBreadcrumbItem>
    //     )
    //   })}
    // </CBreadcrumb>

    <span className="m-0 ms-2">
      <Link to="/">Inicio</Link>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <span key={index}>
            <span> / </span>
            <Link
              {...(breadcrumb.active
                ? {
                    style: { 'pointer-events': 'none', 'text-decoration': 'none' },
                    className: 'text-muted',
                  }
                : { to: breadcrumb.pathname })}
            >
              {breadcrumb.name}
            </Link>
          </span>
        )
      })}
    </span>
  )
}

export default React.memo(AppBreadcrumb)

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import logoInnovo from 'src/assets/images/innovo.png'

import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { globalActions } from '../store'

// sidebar nav config
import navigation from '../_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const barraLateralDesplegable = useSelector((state) => state.global.barraLateralDesplegable)
  const barraLateralVisible = useSelector((state) => state.global.barraLateralVisible)

  return (
    <CSidebar
      position="fixed"
      unfoldable={barraLateralDesplegable}
      visible={barraLateralVisible}
      onVisibleChange={(visible) => {
        dispatch(globalActions.mostrarBarraLateral(visible))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-full" src={logoInnovo} height={40} />
        <CImage className="sidebar-brand-narrow" src={logoInnovo} height={15} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch(globalActions.desplegarBarraLateralDesplegable(!barraLateralDesplegable))
        }
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)

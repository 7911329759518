import React from 'react'

const VentasIndex = React.lazy(() => import('./views/ventas/index'))
const ClientesIndex = React.lazy(() => import('./views/clientes/index'))

const routes = [
  { path: '/', exact: true, name: 'Inicio' },
  { path: '/clientes', name: 'Clientes', element: ClientesIndex },
  { path: '/clientes/ventas/:id?', name: 'Ventas de cliente', element: VentasIndex },
]

export default routes

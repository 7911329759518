import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { alertActions } from '../store'
import { history } from '../helpers'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { asignarToken, desasignarToken } from '../services/api'

// create slice

const name = 'auth'
const initialState = createInitialState()
const reducers = createReducers()
const extraActions = createExtraActions()
const slice = createSlice({ name, initialState, reducers })

// exports

export const authActions = { ...slice.actions, ...extraActions }
export const authReducer = slice.reducer

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    value: secureLocalStorage.getItem('auth'),
  }
}

function createReducers() {
  return {
    setAuth,
  }

  function setAuth(state, action) {
    state.value = action.payload
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/Usuario`

  return {
    login: login(),
    logout: logout(),
  }

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async function ({ username, password }, { dispatch, rejectWithValue }) {
        dispatch(alertActions.clear())

        await axios
          .post(
            `${baseUrl}/Ingreso`,
            {
              mail: username,
              clave: password,
            },
            {
              headers: { 'Content-Type': 'application/json', accept: 'text/plain' },
            },
          )
          .then((response) => {
            // Respondió 200 dado que las credenciales eran correctas
            const token = response.data.datos
            // set auth user in redux state
            dispatch(authActions.setAuth(token))

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            secureLocalStorage.setItem('auth', token)
            asignarToken()

            if (history.location.state !== null) {
              history.location.state = null // Si intenta volver a / porque hemos navegado a 'localhost:3000', no sale del login, entonces lo hacemos null.
            }
            // get return url from location state or default to home page
            const { from } = history.location.state || { from: { pathname: '/clientes' } }
            history.navigate(from)
          })
          .catch((error) => {
            // Error
            if (error.response != null) {
              // console.log('Error: ' + error.response.data.mensaje)
              dispatch(
                alertActions.error(
                  { message: 'Por favor, corrobore usuario y contraseña', showAfterRedirect: true },
                  'alert/error',
                ),
              )
            } else {
              if (error.response) {
                // Devuelve 2xx
                // console.log('Error 1')
              } else if (error.request) {
                // console.log('')
                dispatch(
                  alertActions.error(
                    {
                      message: 'El servidor no responde. Por favor, intente más tarde.',
                      showAfterRedirect: true,
                    },
                    'alert/error',
                  ),
                )
              } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error 3')
              }
            }
            return rejectWithValue(error)
          })
      },
    )
  }

  function logout() {
    return createAsyncThunk(`${name}/logout`, function (arg, { dispatch }) {
      dispatch(authActions.setAuth(null))
      secureLocalStorage.removeItem('auth')
      desasignarToken()
      dispatch(alertActions.clear())
      history.navigate('/login')
    })
  }
}

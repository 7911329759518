import secureLocalStorage from 'react-secure-storage'

export default function authHeader() {
  const token = secureLocalStorage.getItem('auth')

  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}
